.stepsList {
  max-width: 550px;
  margin: auto;
  text-align: left;
  padding-left: calc(var(--marker-offset) + var(--marker-size));

  --marker-size: 27px;
  --marker-offset: 10px;
}

.stepsList > li + li {
  margin-top: 12px;
}

.stepsList > li {
  position: relative;
}

.stepsList > li::before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--marker-size);
  height: var(--marker-size);
  border-radius: 50%;
  position: absolute;
  left: calc(var(--marker-offset) * -1 - var(--marker-size));
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: #fff;
}

.stepsList > li.clickable {
  cursor: pointer;
}

/* Regular number */
.stepsList > li::before {
  content: counter(list-item);
  color: var(--color-content-accent);
  border: 1px solid var(--color-content-accent);
  top: 12px;
}

/* Check icon */
.stepsList > .done::before {
  content: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L5 8L12 1' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
  padding-bottom: 4px;
  color: #fff;
  background-color: var(--color-content-accent);
}

/* Dahsed lines between circles */
.stepsList > li:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  left: calc(
    var(--marker-offset) * -1 - var(--marker-size) + (var(--marker-size) / 2)
  );
  height: 110%;
  top: 20px;
  width: 1px;
  border-left: 1px dashed var(--color-content-8);
}

.stepsList > li.done:not(:last-child)::after {
  border-left-color: var(--color-content-accent);
}

/* Blocked styles */
.stepsList > li.blocked::before {
  border-color: var(--color-content-8);
  color: var(--color-content-8);
}

.stepsList > li.blocked {
  color: var(--color-content-8);
}
